import request from './request'

const api = {
	// 获取列表
	lists: '/partymember/lists',
	// 添加
	add: '/partymember/setDuizhang',
	// 删除
	dele: '/partymember/cancelDuizhang',
	// 编辑
	edit: '/partymember/update',
	// 详情
	info: '/partymember/read',
	// 村
	cunlist: '/village/lists',
}

export {
	request,
	api
}
